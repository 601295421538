// extracted by mini-css-extract-plugin
export var descriptionWithImageWrapper = "shared-module--descriptionWithImageWrapper--1eeiM";
export var infoColumnWrapper = "shared-module--infoColumnWrapper--3jzGw";
export var infoCardWrapper = "shared-module--infoCardWrapper--1Gy7S";
export var contentContainer = "shared-module--contentContainer--C3DHl";
export var mapWrapper = "shared-module--mapWrapper--3IYVT";
export var grayPresentationContainer = "shared-module--grayPresentationContainer--3cQDz";
export var whitePresentationContainer = "shared-module--whitePresentationContainer--1SeKx";
export var roomImage = "shared-module--roomImage--1yosQ";
export var galleryImage = "shared-module--galleryImage--25Amt";
export var imagesContainer = "shared-module--imagesContainer--2Adk8";
export var quoteAuthor = "shared-module--quoteAuthor--QqMsC";
export var quote = "shared-module--quote--3ybtG";
export var quoteContainer = "shared-module--quoteContainer--3FBm6";
export var showLarge = "shared-module--showLarge--1XfC2";
export var showSmall = "shared-module--showSmall--3FLPz";
export var roomDescriptionContainer = "shared-module--roomDescriptionContainer--3MyVf";